<template>
    <b-modal
      v-model="data.showModal"
      size="xl"
      button-size="sm"
      modal-class="doc-modal"
      title-class="w-full"
      footer-class="w-full"
      no-fade
      @show="onShow"
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      scrollable
    >
      <!-- Modal header -->
      <div slot="modal-title">
        <div class="flex w-full justify-between">
          <div v-if="doc">
            <div v-if="editMode" class="flex flex-row gap-2 items-center">
              <b-form-input
                v-model="doc.title"
                placeholder="Title"
                class="doc-title"
              ></b-form-input>
              <button
                class="btn btn-primary btn-xs"
                @click="onSaveDocument"
                :disabled="isLoading"
              >
                Save
              </button>
            </div>
            <div v-else class="flex flex-row gap-2 items-center">
              <h5>{{ doc.title }}</h5>
              <button
                class="btn btn-primary btn-xs"
                @click="editMode = true;"
              >
                Edit
              </button>
            </div>
          </div>
          <div>
            <b-btn
              size="sm"
              variant="danger"
              :disabled="isLoading"
              @click="onDeleteDocument"
            >
              Delete document
            </b-btn>
          </div>
        </div>
      </div>

      <!-- Modal content -->
      <div v-if="isLoading && !doc">
        Loading..
      </div>
      <div v-else class="justify-center items-center">
        <img
          v-if="currentImage"
          class="image-height object-contain m-auto"
          :src="currentImageUrl"
          alt="Current image"
        >
        <h5
          v-else
          class="text-center image-height"
        >
          No images available
        </h5>
      </div>

      <!-- Modal footer -->
      <div slot="modal-footer" class="w-full d-flex gap-4 justify-content-between items-center">
        <div class="flex w-full justify-content-between">
          <div>
            <b-pagination
              v-if="images.length"
              v-model="imagePage"
              :total-rows="images.length"
              per-page="1"
            ></b-pagination>
          </div>

          <div class="flex gap-4">
            <b-form @submit.prevent="onUploadImage">
              <b-form-file
                ref="uploadFile"
                v-model="upload.file"
                required
                :state="Boolean(upload.file)"
                placeholder="Upload image"
                drop-placeholder="Drop file here..."
                label-size="sm"
                class="ml-2"
                accept=".jpg, .png"
                :disabled="upload.busy"
                @input="onUploadImageSelected"
              ></b-form-file>
            </b-form>

            <div class="flex gap-2">
              <b-btn
              size="sm"
              variant="danger"
              :disabled="isLoading || !currentImage"
              @click="onDeleteImage"
            >
              Delete image
            </b-btn>

              <b-btn
                size="sm"
                variant="white"
                :disabled="isLoading"
                @click="onClose"
              >
                Close
              </b-btn>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </template>

<script>
export default {
  name: 'docModal',
  components: {},
  props: ['data'],
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    currentImage() {
      if (this.images.length === 0) return null;
      const currentImage = this.images[this.imagePage - 1];
      return currentImage;
    },
    currentImageUrl() {
      if (!this.currentImage) return null;
      const url = `/api/document/${this.currentImage.filename}`;
      return url;
    },
  },
  data() {
    return {
      loadingCount: 0,
      doc: null,
      imagePage: 1,
      images: [],
      editMode: false,
      upload: {
        file: null,
        busy: false,
      },
    };
  },
  methods: {
    onShow() {
      this.doc = JSON.parse(JSON.stringify(this.data.doc));
      this.fetchImages();
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
    onSaveDocument() {
      this.loadingCount++;
      const doc = {
        title: this.doc.title,
      };
      this.$http
        .put(`/template_doc/${this.doc.id}`)
        .send({ doc })
        .then(() => {
          this.data.doc.title = doc.title;
          this.editMode = false;
        })
        .catch((err) => {
          this.$toast.error(`Failed to save doc: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    onDeleteDocument() {
      if (!confirm('Do you really wish to delete this document?')) {
        return;
      }
      this.loadingCount++;
      this.$http
        .delete(`/template_doc/${this.doc.id}`)
        .then(() => {
          this.$emit('deleted', this.doc.id);
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete doc: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    onDeleteImage() {
      if (!confirm('Do you really wish to delete this image?')) {
        return;
      }
      this.loadingCount++;
      this.$http
        .delete(`/template_doc/${this.doc.id}/images/${this.currentImage.id}`)
        .then(() => {
          const imagePage = Math.min(Math.max(this.imagePage, 1), this.images.length - 1);
          this.images = this.images.filter((image) => image.id !== this.currentImage.id);
          this.imagePage = imagePage;
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete image: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    onUploadImage() {
      this.upload.busy = true;
      const formData = new FormData();
      formData.append('image', this.upload.file);

      this.$http
        .post(`/template_doc/${this.doc.id}/images`)
        .send(formData)
        .then((res) => {
          this.images.push(res.body.data);
          this.$nextTick(() => {
            this.imagePage = this.images.length;
          });
        })
        .catch((err) => {
          this.$toast.error(`Failed to upload file: ${err.response.text}`);
        })
        .finally(() => {
          this.upload.file = null;
          this.upload.busy = false;
        });
    },
    onUploadImageSelected() {
      if (this.upload.file) {
        this.onUploadImage();
      }
    },
    fetchImages() {
      this.loadingCount++;
      this.$http
        .get(`/template_doc/${this.doc.id}/images`)
        .then((res) => {
          this.images = res.body.images;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch images: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
  },
};
</script>

<style lang="scss">
.doc-modal {
  .menubar {
    margin: 10px 0;
  }

  .menubar__button {
    background: transparent;
    border: 0;
    padding: 6px 12px;
    font-size: 14px;

    &:hover {
      background: rgba(0,0,0,.05);
    }
  }

  .menubar__button.is-active {
    background: #ccc;
  }

  .doc-title {
    min-width: 300px;
  }

  .image-height {
    height: 70vh;
  }
}

</style>
